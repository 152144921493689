import { ClientLockInfo } from '@packages/realtime/types';
import { atom } from 'jotai';
import { atomWithReset, RESET } from 'jotai/utils';

import { OrderDoc } from '@/lib/db/models';
import { debugs } from '@/lib/debug';
import { getRxdb } from '@/providers/RxdbProvider';
import { OrderStatus } from '@/schema.types';

const d = debugs.quickship.extend('state');

export type LockInfo = { skuHash?: string | null };

export const ownLockInfoAtom = atom<Omit<ClientLockInfo, 'clientId'>>({ lockedSkuHash: null });

export const othersLockInfoAtom = atom<ClientLockInfo[]>([]);

const wait = (ms: number) => new Promise((resolve) => setTimeout(resolve, ms));

export const locksInfoAtom = atom((get) => {
	const otherLocksInfo = get(othersLockInfoAtom);
	const activeSkuHash = get(activeOrdersBundleAtom)?.skuHash ?? null;

	const lockedSkuHashes = new Set(otherLocksInfo.map((v) => v.lockedSkuHash).filter((v) => v && v !== activeSkuHash));

	return {
		lockedSkuHashes,
		// lockedOrderIds: Array.from(lockedOrderIdsByAblyClientId.values()).flat(),
	};
});

const _activeOrdersBundleLockedAtAtom = atomWithReset<Date | null>(null);
const _activeOrderIdsBundleAtom = atomWithReset<OrderDoc['id'][]>([]);
const _activeOrdersBundleSkuHashAtom = atomWithReset<string | null>(null);
const _activeOrdersBundleSkus = atomWithReset<string[] | null>(null);

export const activeOrdersBundleAtom = atom(
	(get) => {
		const activeOrderIdsBundle = get(_activeOrderIdsBundleAtom);
		const activeOrdersBundleLockedAt = get(_activeOrdersBundleLockedAtAtom);
		const activeOrdersBundleSkuHash = get(_activeOrdersBundleSkuHashAtom);
		const activeOrdersBundleSkus = get(_activeOrdersBundleSkus);

		return activeOrderIdsBundle.length === 0
			? null
			: {
					orderIds: activeOrderIdsBundle,
					lockedAt: activeOrdersBundleLockedAt,
					skuHash: activeOrdersBundleSkuHash,
					skus: activeOrdersBundleSkus,
				};
	},

	async (get, set, skuHash: string | null) => {
		const rxdb = getRxdb();

		d('activeOrdersBundleAtom:update', skuHash);

		if (skuHash === null) {
			set(ownLockInfoAtom, (prev) => ({ ...prev, lockedSkuHash: null }));

			await wait(250);

			set(_activeOrdersBundleLockedAtAtom, RESET);
			set(_activeOrderIdsBundleAtom, RESET);
			set(_activeOrdersBundleSkuHashAtom, RESET);
			set(_activeOrdersBundleSkus, RESET);
		} else {
			const now = new Date();

			d('query open orders', { skuHash, now, rxdb: rxdb });

			const openOrdersWithSkuHash =
				(await rxdb?.orders
					.find({ selector: { skuHash, status: OrderStatus.Open, cat: { $lte: now.toISOString() } } })
					.exec()) ?? [];

			d(
				'open orders',
				openOrdersWithSkuHash.map((order) => order.id),
			);

			if (openOrdersWithSkuHash.length > 0) {
				d('found open orders', openOrdersWithSkuHash);

				set(_activeOrdersBundleSkuHashAtom, skuHash);
				set(_activeOrdersBundleLockedAtAtom, now);

				set(
					_activeOrderIdsBundleAtom,
					openOrdersWithSkuHash.map((order) => order.id),
				);

				set(_activeOrdersBundleSkus, openOrdersWithSkuHash[0].skus);

				set(ownLockInfoAtom, (prev) => ({
					...prev,
					lockedSkuHash: skuHash,
				}));
			}
		}
	},
);
