// TODO: https://developer.dhl.com/sites/default/files/inline-images/Avail.%20Services%20new_2.png
export const config = {
    credentials: {
        apiKey: 'Sg4zHVek1mrScATeu187YvH1N2qvWN6k',
        apiSecret: process.env.DHL_API_SECRET,
    },
    sandbox: {
        username: 'sandy_sandbox',
        password: 'pass',
        ekp: '3333333333',
        baseUrl: 'https://api-sandbox.dhl.com/parcel/de/shipping/v2',
    },
    production: {
        baseUrl: 'https://api-eu.dhl.com/parcel/de/shipping/v2',
    },
};
export const PAKET_MAX_WEIGHT_GRAMS = 31500;
export var DHL_PRODUCT;
(function (DHL_PRODUCT) {
    DHL_PRODUCT["V01PAK"] = "V01PAK";
    DHL_PRODUCT["V53WPAK"] = "V53WPAK";
    DHL_PRODUCT["V54EPAK"] = "V54EPAK";
    DHL_PRODUCT["V62WP"] = "V62WP";
    DHL_PRODUCT["V66WPI"] = "V66WPI";
    DHL_PRODUCT["V07PAK"] = "V07PAK";
})(DHL_PRODUCT || (DHL_PRODUCT = {}));
export const DhlApi = {
    parcelDeShippingReturnsV1Orders: {
        origin: {
            sandbox: 'https://api-sandbox.dhl.com',
            production: 'https://api-eu.dhl.com',
        },
        pathname: '/parcel/de/shipping/returns/v1/orders',
        TReturnType: {},
        referenceDocs: 'https://developer.dhl.com/api-reference/dhl-paket-de-retoure-post-paket-deutschland#reference-docs-section',
    },
    parcelDeShippingPostParcelGermanyV2: {
        origin: {
            sandbox: 'https://api-sandbox.dhl.com',
            production: 'https://api-eu.dhl.com',
        },
        pathname: '/parcel/de/shipping/v2',
        TReturnType: {},
        referenceDocs: 'https://developer.dhl.com/api-reference/parcel-de-shipping-post-parcel-germany-v2#reference-docs-section',
    },
};
export const DHL_LABEL_PRINT_FORMAT = {
    FORMAT_A4: { key: 'FORMAT_A4', value: 'A4', name: 'Laserdruck A4 Normalpapier' },
    FORMAT_910300600: {
        key: 'FORMAT_910300600',
        value: '910-300-600',
        name: 'Thermodruck (Faltband) 103x199mm (910-300-600)',
    },
    FORMAT_910300610: {
        key: 'FORMAT_910300610',
        value: '910-300-610',
        name: 'Thermodruck (Rolle) 103x199mm (910-300-610)',
    },
    FORMAT_910300700: {
        key: 'FORMAT_910300700',
        value: '910-300-700',
        name: 'Laserdruck (Bogen A5) 105x208mm (910-300-700)',
    },
    FORMAT_910300700Oz: {
        key: 'FORMAT_910300700Oz',
        value: '910-300-700-oz',
        name: 'Laserdruck (Bogen A5) 105x208mm (910-300-700) ohne Zusatzetiketten',
    },
    FORMAT_910300710: { key: 'FORMAT_910300710', value: '910-300-710', name: 'Laserdruck 105x209mm (910-300-710)' },
    FORMAT_910300300: {
        key: 'FORMAT_910300300',
        value: '910-300-300',
        name: 'Laserdruck (Bogen A5) 105x148mm (910-300-300)',
    },
    FORMAT_910300300Oz: {
        key: 'FORMAT_910300300Oz',
        value: '910-300-300-oz',
        name: 'Laserdruck (Bogen A5) 105x148mm (910-300-300) ohne Zusatzetiketten',
    },
    FORMAT_910300400: {
        key: 'FORMAT_910300400',
        value: '910-300-400',
        name: 'Thermodruck (Faltband) 103x150mm (910-300-400)',
    },
    FORMAT_910300410: {
        key: 'FORMAT_910300410',
        value: '910-300-410',
        name: 'Thermodruck (Rolle) 103x150mm (910-300-410)',
    },
    FORMAT_100x70mm: { key: 'FORMAT_100x70mm', value: '100x70mm', name: 'Warenpost 100x70mm' },
};
/**
 * see https://developer.dhl.com/sites/default/files/inline-images/2023-07-03_Req_for%20shipping.png
 */
export const DHL_PRODUCT_DIMENSIONS = {
    [DHL_PRODUCT.V01PAK]: {
        minLengthCm: 15,
        maxLengthCm: 120,
        maxLengthBulkyCm: 200,
        minWidthCm: 11,
        maxWidthCm: 60,
        minHeightCm: 1,
        maxHeightCm: 60,
        minWeightKg: 0.01,
        maxWeightKg: 31.5,
    },
    [DHL_PRODUCT.V53WPAK]: {
        minLengthCm: 15,
        maxLengthCm: 120,
        maxLengthBulkyCm: 200,
        minWidthCm: 11,
        maxWidthCm: 60,
        minHeightCm: 1,
        maxHeightCm: 60,
        minWeightKg: 0.01,
        maxWeightKg: 31.5,
    },
    [DHL_PRODUCT.V54EPAK]: {
        minLengthCm: 15,
        maxLengthCm: 120,
        minWidthCm: 11,
        maxWidthCm: 60,
        minHeightCm: 3.5,
        maxHeightCm: 60,
        minWeightKg: 0.01,
        maxWeightKg: 31.5,
    },
    [DHL_PRODUCT.V62WP]: {
        minLengthCm: 10,
        maxLengthCm: 35,
        minWidthCm: 7,
        maxWidthCm: 25,
        minHeightCm: 0.1,
        maxHeightCm: 5,
        minWeightKg: 0.01,
        maxWeightKg: 1,
    },
    [DHL_PRODUCT.V66WPI]: {
        minLengthCm: 14,
        maxLengthCm: 35.3,
        minWidthCm: 9,
        maxWidthCm: 25,
        minHeightCm: 0.1,
        maxHeightCm: 10,
        minWeightKg: 0.01,
        maxWeightKg: 1,
    },
    [DHL_PRODUCT.V07PAK]: {},
};
export const dhlProducts = {
    paket: {
        name: 'DHL Paket (ohne Services)',
        params: (billingNumber = '33333333330101') => ({
            billingNumber,
            product: DHL_PRODUCT.V01PAK,
        }),
    },
    paketMitServices: {
        name: 'DHL Paket (mit Services)',
        params: (billingNumber = '33333333330102') => ({
            billingNumber,
            product: DHL_PRODUCT.V01PAK,
        }),
    },
    paketGreen: {
        name: 'DHL Paket (mit GoGreen)',
        params: (billingNumber = '33333333330103') => ({
            billingNumber,
            product: DHL_PRODUCT.V01PAK,
        }),
    },
    paketInternational: {
        name: 'DHL Paket International',
        params: (billingNumber = '33333333335301') => ({
            billingNumber,
            product: DHL_PRODUCT.V53WPAK,
        }),
    },
    paketInternationalGreen: {
        name: 'DHL Paket International (mit GoGreen)',
        params: (billingNumber = '33333333335302') => ({
            billingNumber,
            product: DHL_PRODUCT.V53WPAK,
        }),
    },
    europaket: {
        name: 'DHL Europaket',
        params: (billingNumber = '33333333335401') => ({
            billingNumber,
            product: DHL_PRODUCT.V54EPAK,
        }),
    },
    europaketGreen: {
        name: 'DHL Europaket (mit GoGreen)',
        params: (billingNumber = '33333333335402') => ({
            billingNumber,
            product: DHL_PRODUCT.V54EPAK,
        }),
    },
    warenpost: {
        name: 'Warenpost',
        params: (billingNumber = '33333333336201') => ({
            billingNumber,
            product: DHL_PRODUCT.V62WP,
        }),
    },
    warenpostGreen: {
        name: 'Warenpost (mit GoGreen)',
        params: (billingNumber = '33333333336202') => ({
            billingNumber,
            product: DHL_PRODUCT.V62WP,
        }),
    },
    warenpostInternational_1: {
        name: 'Warenpost International',
        params: (billingNumber = '33333333336601') => ({
            billingNumber,
            product: DHL_PRODUCT.V66WPI,
        }),
    },
    warenpostInternational_2: {
        name: 'Warenpost International',
        params: (billingNumber = '33333333336602') => ({
            billingNumber,
            product: DHL_PRODUCT.V66WPI,
        }),
    },
    warenpostInternational_3: {
        name: 'Warenpost International',
        params: (billingNumber = '33333333336603') => ({
            billingNumber,
            product: DHL_PRODUCT.V66WPI,
        }),
    },
    warenpostInternationalGreen: {
        name: 'Warenpost International (mit GoGreen)',
        params: (billingNumber = '33333333336604') => ({
            billingNumber,
            product: DHL_PRODUCT.V66WPI,
        }),
    },
    retoure: {
        name: 'DHL Retoure für DHL Paket und Warenpost',
        params: (billingNumber = '33333333330701') => ({
            billingNumber,
            product: DHL_PRODUCT.V07PAK,
        }),
    },
    retoureGreen: {
        name: 'DHL Retoure (mit GoGreen)',
        params: (billingNumber = '33333333330702') => ({
            billingNumber,
            product: DHL_PRODUCT.V07PAK,
        }),
    },
};
