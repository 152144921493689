'use client';

import { createContext } from 'react';

import {
	WarehouseActionsContext as TWarehouseActionsContext,
	WarehouseContext as TWarehouseContext,
} from './warehouse-types';

export const initialWarehouseContext: TWarehouseContext = {
	markets: [],
};

export const WarehouseContext = createContext<TWarehouseContext>(initialWarehouseContext);

export const initialWarehouseActionsContext: TWarehouseActionsContext = {
	// eslint-disable-next-line @typescript-eslint/no-unsafe-return
	requestShippingLabels: () => Promise.resolve() as any,
	// eslint-disable-next-line @typescript-eslint/no-unsafe-return
	shipOrders: () => Promise.resolve() as any,
	// eslint-disable-next-line @typescript-eslint/no-unsafe-return
	print: () => Promise.resolve(),
};

export const WarehouseActionsContext = createContext<TWarehouseActionsContext>(initialWarehouseActionsContext);
