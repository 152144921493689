import { tenantConfig } from './fixtures/tenantConfig';
import { WarehouseProduct } from './warehouse-types';

/** Units of measurement for weight. */
export enum WeightUnit {
	/** Metric system unit of mass. */
	Grams = 'GRAMS',
	/** 1 kilogram equals 1000 grams. */
	Kilograms = 'KILOGRAMS',
	/** Imperial system unit of mass. */
	Ounces = 'OUNCES',
	/** 1 pound equals 16 ounces. */
	Pounds = 'POUNDS',
}

/** The possible product statuses. */
export enum ProductStatus {
	/** The product is ready to sell and can be published to sales channels and apps. Products with an active status aren't automatically published to sales channels, such as the online store, or apps. By default, existing products are set to active. */
	Active = 'ACTIVE',
	/** The product is no longer being sold and isn't available to customers on sales channels and apps. */
	Archived = 'ARCHIVED',
	/** The product isn't ready to sell and is unavailable to customers on sales channels and apps. By default, duplicated and unarchived products are set to draft. */
	Draft = 'DRAFT',
}

export type GetProductVariantsQuery = {
	__typename?: 'QueryRoot';
	productVariants: {
		__typename?: 'ProductVariantConnection';
		pageInfo: { __typename?: 'PageInfo'; endCursor?: string | null; hasNextPage: boolean };
		nodes: Array<{
			__typename?: 'ProductVariant';
			id: string;
			sku?: string | null;
			title: string;
			displayName: string;
			price: any;
			availableForSale: boolean;
			position: number;
			weight?: number | null;
			weightUnit: WeightUnit;
			sellableOnlineQuantity: number;
			image?: { __typename?: 'Image'; url: any; width?: number | null; height?: number | null } | null;
			product: {
				__typename?: 'Product';
				status: ProductStatus;
				productType: string;
				title: string;
				totalInventory: number;
				vendor: string;
				images: {
					__typename?: 'ImageConnection';
					nodes: Array<{ __typename?: 'Image'; url: any; height?: number | null; width?: number | null }>;
				};
			};
		}>;
	};
};

// eslint-disable-next-line @typescript-eslint/require-await
export const getProductMap = async (tenantPk: string) => {
	const products = tenantConfig[tenantPk].products ?? [];
	return new Map<string, WarehouseProduct>(products.map((p) => [p.sku, p]));
};
